<template>
  <div>
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-32 mb-3">Crea tu cuenta</div>
      <div class="mb-4">
      </div>

      <a-form-model
        class="mb-4"
        ref="rule-Form"
        :model="form"
        :rules="rules"
        layout="vertical"
        :wrapper-col="{ span: 24}"
      >
        <template v-if="['development','quality'].includes(environment)">
          <a-form-model-item ref="name"  prop="name" >
            <a-input v-model.trim="form.name" size="large"  placeholder="Nombre"/>
          </a-form-model-item>
          <a-form-model-item ref="email"  prop="email" >
            <a-input v-model.trim="form.email" size="large" placeholder="Correo Electrónico"/>
          </a-form-model-item>
          <a-form-model-item ref="password"  prop="password" >
            <a-input v-model.trim="form.password"
                     type="password"
                     size="large"
                     placeholder="Contraseña"/>
          </a-form-model-item>
          <a-form-model-item ref="passwordConfirmation" prop="passwordConfirmation" >
            <a-input v-model.trim="form.passwordConfirmation"
                     type="password"
                     size="large"
                     placeholder="Contraseña"/>
          </a-form-model-item>
          <a-button
            type="primary"
            size="large"
            class="text-center w-100"
            :loading="loading"
            @click="handleSubmit"
          >
            <strong>Registrarse</strong>
          </a-button>
        </template>
        <socialite-buttons/>
      </a-form-model>
      <div>
        <span class="mr-1">Al registrarse, acepta los </span>
        <a href="/resources/general_files/termCondi.pdf" target="_blank" class="kit__utils__link">Términos de Servicio</a> y
        <a href="/resources/general_files/polPrivacidad.pdf" target="_blank" class="kit__utils__link">Política de Privacidad</a>
      </div>
    </div>
    <div class="text-center pt-2 mb-auto">
      <span class="mr-2">¿Ya tienes una cuenta?</span>
      <router-link to="/auth/login" class="kit__utils__link font-size-16">Ingresar</router-link>
    </div>
  </div>
</template>
<script>
import socialiteButtons from '../socialiteButtons/socialiteButtons'
export default {
  name: 'register',
  components: {
    socialiteButtons,
  },
  computed: {
    loading() {
      return this.$store.state.user.loading
    },
  },
  data: function () {
    return {
      form: {
        name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
      },
      rules: {
        name: [
          { required: true, message: 'El Correo Electrónico es obligatorio.', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'El Correo Electrónico es obligatorio.', trigger: 'blur' },
          { type: 'email', message: 'El Correo Electrónico no es válido.', trigger: 'blur' },
        ],
        password: [
          { required: true, message: 'La contraseña es obligatoria.', trigger: 'blur' },
          { type: 'string', message: 'La contraseña no es válida.', trigger: 'blur' },
          { min: 8, message: 'La contraseña debe contener al menos 8 caracteres', trigger: 'blur' },
        ],
        passwordConfirmation: [
          { required: true, message: 'El campo Contraseña es obligatorio.', trigger: 'blur' },
          { type: 'string', message: 'El campo Contraseña no es válido.', trigger: 'blur' },
          { min: 8, message: 'La contraseña debe contener al menos 8 caracteres', trigger: 'blur' },
          {
            validator: () => {
              if (this.form.password === this.form.passwordConfirmation) {
                return true
              } else {
                return false
              }
            },
            message: 'Las Contraseñas no coinciden.',
            trigger: 'blur',
          },
        ],
      },
      environment: process.env.VUE_APP_ENVIRONMENT,
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          this.$store.dispatch('user/REGISTER', { payload: this.form })
        } else {
          return false
        }
      })
    },
    loginSocialite(provider) {
      this.$store.dispatch('user/SOCIALITE_LOGIN', { payload: provider })
    },
  },
}
</script>
<style lang="scss" module>
@import "@/@airui/system/Auth/style.module.scss";
</style>
